$content-width: 85%;

@import
  "minima/skins/classic",
  "minima/initialize";

/* Figure Formatting */

figure {
    display: inline-block;
    margin: auto;
    text-align: center;
}

figure img {
    display: block;
    vertical-align: bottom;
    text-align: center;
}

figure figcaption {
    text-align: center;
    background-color: cornsilk;
    font-size: 85%;
    font-style: italic;
}

.grid {
  display: flex;
 }
.col-1-2 {
  flex: 1;
}
.cole-1-2:last-child {
  margin-left: 20px;
}


/* Dropdown Menus */

.dropdown {
    position: relative;
    display: inline-block;
    padding-right: 10px;

}

.dropdown-content {
    display: none;
    line-height:1em;
    /*margin-left: 05%;*/
    position: absolute;
    text-align: left;
    background-color: #f9f9f9;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
}

.sub-dropdown {
    position: relative;
    display: inline-block;
    \\padding: 10px;

}

.sub-dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 175px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.sub-dropdown:hover .sub-dropdown-content {
    display: block;
}


/* Sizing for image Galleries */

.gal {
    max-width: 19% // 5 Across
}

.wp {
    max-width: 32% // 3 Across
}

.duo {
    max-width: 45% // 2 Across
}

.trio {
    max-width: 32% // 3 Across
}

.quad {
    max-width: 24% // 4 Across
}
.sing {
    max-width: 90% // Single Image
}

.library {
    max-width: 35%; // For the Library Listing
    border-style:solid;
    border-width:1px;
}

.ctr {
    margin: auto;
}
